import { CSSProperties } from 'react';

export enum TreeNodeType {
  BOX = 'BOX',
  TEXT = 'TEXT',
  INPUT = 'INPUT',
  IMAGE = 'IMAGE',
  BUTTON = 'BUTTON',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  HTML = 'HTML',
  DISCOUNT = 'DISCOUNT',
}

export type Alignment = 'left' | 'top' | 'bottom' | 'right' | 'center';

export type TreeNodeAlignment = Alignment | [Alignment, Alignment];

export type Direction = 'LR' | 'TB';

export type Fill = boolean | string;

export type DateFormat = 'MM/DD/YYYY' | 'DD/MM/YYYY' | 'YYYY/MM/DD';

export type Size =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '0'
  | 'full';

export type InputStyle = 'regular' | 'minimal';

export type BackgroundDimensions = {
  type: 'auto' | 'fixed';
  pos: [number, number];
  zoom: number;
};

export interface BaseTreeNode {
  id: string;
  type: TreeNodeType;
  attr?: unknown;
  version?: `${number}.${number}.${number}`;
}

type BaseTreeNodeAttributes<T> = T & {
  css?: React.CSSProperties;
  hidden?: boolean;
};

export type FormWidgetTheme = {
  headCol: string;
  bodyCol: string;
  accent: string;
  radius: Size;
  width: number;
  height?: number;
  fixedHeight?: boolean;
  scale?: number;
};

export type TreeNode = BaseTreeNode &
  (
    | {
        type: TreeNodeType.HTML;
        attr?: BaseTreeNodeAttributes<
          Partial<{
            button: Record<string, Record<string, {}>>;
            input: Record<string, Record<string, {}>>;
          }>
        >;
        children?: string[];
      }
    | {
        type: TreeNodeType.BOX;
        attr?: BaseTreeNodeAttributes<
          Partial<{
            align: TreeNodeAlignment;
            dir: Direction;
            bgUrl: string;
            pad: Size;
            bgColor: string;
            fill: Fill;
            radius?: Size;
            theme?: Partial<FormWidgetTheme>;
            borderCol?: string;
            borderWidth?: Size;
            bgDims?: BackgroundDimensions;
            bgHidden?: boolean;
          }>
        >;
        children?: TreeNode[];
      }
    | {
        type: TreeNodeType.TEXT;
        attr?: BaseTreeNodeAttributes<
          Partial<{
            color: string;
            size: Size;
          }>
        >;
        children: string;
      }
    | {
        type: TreeNodeType.IMAGE;
        attr?: BaseTreeNodeAttributes<{
          url: string;
          height?: number;
        }>;
        children?: undefined;
      }
    | {
        type: TreeNodeType.INPUT;
        attr?: BaseTreeNodeAttributes<{
          color: string;
          placeholder: string;
          fieldType?: string;
          mapAttribute?: string;
          required?: boolean;
          borderCol?: string;
          radius?: Size;
          bgColor?: string;
          style?: InputStyle;
          showLabel?: boolean;
        }>;
        children: string;
      }
    | {
        type: TreeNodeType.DATE;
        attr?: BaseTreeNodeAttributes<{
          color: string;
          placeholder: string;
          fieldType?: string;
          mapAttribute?: string;
          dateFormat?: DateFormat;
          required?: boolean;
          borderCol?: string;
          radius?: Size;
          bgColor?: string;
          style?: InputStyle;
          showLabel?: boolean;
        }>;
        children: string;
      }
    | {
        type: TreeNodeType.CHECKBOX;
        attr?: BaseTreeNodeAttributes<{
          color: string;
          size: Size;
        }>;
        children: string;
      }
    | {
        type: TreeNodeType.BUTTON;
        attr?: BaseTreeNodeAttributes<{
          color: string;
          bgColor: string;
          radius?: Size;
          borderCol?: string;
          borderWidth?: Size;
        }>;
        children: string;
      }
    | {
        type: TreeNodeType.DISCOUNT;
        attr?: BaseTreeNodeAttributes<{
          code?: string;
        }>;
        children: undefined;
      }
    | {
        type: TreeNodeType.TEXT;
        children: string;
        attr?: BaseTreeNodeAttributes<
          Partial<{
            color: string;
            size: Size;
          }>
        >;
      }
  );

export type TreeNodeByType<T = keyof typeof TreeNodeType> = Extract<
  TreeNode,
  { type: T }
>;

export type OverrideNodes = Record<
  string,
  | (({
      defaultStyles,
      node,
    }: {
      defaultStyles: CSSProperties;
      node: TreeNode;
    }) => JSX.Element)
  | string
>;

export type CodeOrPreview = 'code' | 'preview';

export type ImagePosition = 'left' | 'right' | 'top' | 'background';
