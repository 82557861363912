import { subSeconds } from 'date-fns';
import { AllTimeTimeFilter } from 'src/components/HistoricalDaterangePicker/types';
import {
  FlyOutData,
  IOSWidgetData,
  OptinsData,
} from 'src/modules/optins/models';
import {
  FormWidget,
  OptinFlow,
  OptinFlowNode,
  OptinStatistic,
} from 'src/modules/optins/models/types';
import { OptinRenderType, Status } from '../constants';
import { get, patch, post } from './axios';

const CONFIG_OPTIN_ENDPOINT = '/dashboard/config/optin/';
const CONFIG_HOME_ENDPOINT = '/dashboard/config/js-sdk/home/';
const CONFIG_IOS_WIDGET_ENDPOINT = '/dashboard/config/ios-prompt/';

const OPTIN_FLOWS_ENDPOINT = '/dashboard/optin-flows/';
const TEMPLATES_ENDPOINT = `${OPTIN_FLOWS_ENDPOINT}templates/`;

export const fetchOptinTemplatesAPI = (channels: string[]) =>
  get(`${TEMPLATES_ENDPOINT}?channels=${channels.join(',')}`);

export const createOptinFlowFromTemplateAPI = (templateId: number) =>
  post(`${OPTIN_FLOWS_ENDPOINT}`, { template_id: templateId });

export const fetchOptinByIdAPI = (id: number) =>
  get(`${OPTIN_FLOWS_ENDPOINT}${id}/`);

export const listOptinFlowsAPI = ({
  startTime,
  endTime,
  status,
  type,
}: {
  startTime?: number;
  endTime?: number;
  status?: Status;
  type?: OptinRenderType;
}): Promise<{
  data: { flows: OptinFlow[]; overall_statistics: OptinStatistic };
  error: string;
}> => {
  const defaultStartTime = subSeconds(
    Date.now(),
    AllTimeTimeFilter.value,
  ).getTime();
  const defaultEndTime = Date.now();

  // weirdly backend only accepts time in seconds (first 10 digits)
  const newStartTime = Math.floor((startTime || defaultStartTime) / 1000);
  const newEndTime = Math.floor((endTime || defaultEndTime) / 1000);

  return get(
    `${OPTIN_FLOWS_ENDPOINT}?start_time=${newStartTime}&end_time=${newEndTime}${
      status ? `&status=${status}` : ''
    }${type ? `&optin_type=${type}` : ''}`,
  );
};

export const patchOptinFlowAPI = (
  id: number | string,
  data: Partial<OptinFlow>,
) => patch(`${OPTIN_FLOWS_ENDPOINT}${id}/`, data);

export const sortOptinFlowAPI = (flowId: number, nodeIds: Array<number>) =>
  post(`${OPTIN_FLOWS_ENDPOINT}${flowId}/flow-nodes/reorder/`, {
    ordered_node_ids: nodeIds,
  });

export const createOptinFlowNodeAPI = (
  flowId: number,
  data: Partial<OptinFlowNode>,
) => post(`${OPTIN_FLOWS_ENDPOINT}${flowId}/flow-nodes/`, data);

export const patchOptinFlowNodeAPI = ({
  flowId,
  nodeId,
  data,
}: {
  flowId: number;
  nodeId: number;
  data: Partial<OptinFlowNode>;
}) => patch(`${OPTIN_FLOWS_ENDPOINT}${flowId}/flow-nodes/${nodeId}/`, data);

export const patchOptinFlowFormWidgetAPI = ({
  flowId,
  nodeId,
  formId,
  data,
}: {
  flowId: number;
  nodeId: number;
  formId: number;
  data: Partial<FormWidget>;
}) =>
  patch(
    `${OPTIN_FLOWS_ENDPOINT}${flowId}/flow-nodes/${nodeId}/forms/${formId}/`,
    data,
  );

export const fetchOptinConfigAPI = () => get(CONFIG_OPTIN_ENDPOINT);

export const setOptinConfigAPI = (data: OptinsData) =>
  post(CONFIG_OPTIN_ENDPOINT, data);

export const fetchFlyoutConfigAPI = () => get(CONFIG_HOME_ENDPOINT);

export const setFlyoutConfigAPI = (data: { flyout: FlyOutData }) =>
  post(CONFIG_HOME_ENDPOINT, data);

export const fetchIOSWidgetConfigAPI = () => get(CONFIG_IOS_WIDGET_ENDPOINT);

export const setIOSWidgetConfigAPI = (data: IOSWidgetData) =>
  post(CONFIG_IOS_WIDGET_ENDPOINT, data);

export const validateOptinFlowAPI = (id: number) =>
  post(`${OPTIN_FLOWS_ENDPOINT}${id}/validate/`, {});
