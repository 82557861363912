import { get, post } from './axios';

export const getStepAPI = () => get(`/dashboard/onboarding/sms/`);

export const setStepAPI = data => post(`/dashboard/onboarding/sms/`, data);

export const getSMSStatsAllTime = () =>
  get(`/dashboard/attributed-revenue/summary?channel=sms`);

export const getSMSStatsTimeFilter = (start_date, end_date) =>
  get(
    `/dashboard/attributed-revenue/summary?start_date=${start_date}&end_date=${end_date}&channel=sms`,
  );
